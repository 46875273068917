import { get } from './utils'

const state = {
  menu: [
    {
      "name": "home",
      "path": "/",
      "text": "Portfolio"
    },
    {
      "name": "blog",
      "path": "/blog",
      "text": "Blog"
    }
  ]
}
const getters = {
  menu: get('menu')
}
const mutations = {

}
const actions = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
