import Vue from 'vue'
import Vuex from 'vuex'
import { set, toggle } from './utils'
import app from './app'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: { app },
  state: {
    drawer: null
  },
  mutations: {
    setDrawer: set('drawer'),
    toggleDrawer: toggle('drawer')
  }
})
