import Vue from "vue"
import Router from "vue-router"
import Home from "./views/Home.vue"

Vue.use(Router)

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        title: 'Profile',
        metaTags: [
          {
            name: 'description',
            content: 'Software engineer based in Washington state. Personal profile and blog focused primarily on web technologies.'
          }
        ]
      }
    },
    {
      path: "/blog",
      name: "blog",
      component: () =>
        import(/* webpackChunkName: "bloglist" */ "./views/BlogList.vue"),
      meta: {
        title: 'Blog - List',
        metaTags: [
          {
            name: 'description',
            content: 'Collection of personal hurdles overcome with ferverous head-to-keyboard-ing.'
          }
        ]
      }
    },
    {
      path: "/blog/:friendlyurl",
      name: "blog-post",
      props: true,
      component: () =>
        import(/* webpackChunkName: "blogpost" */ "./views/BlogPost.vue"),
      meta: {
        title: 'Blog - '
      }
    },
    {
      path: '/404',
      name: 'not-found',
      component: () => import(/* webpackChunkName: "404" */ "./views/404.vue"),
      meta: {
        title: '404 Not Found'
      }
    }
  ]
})

const routeToTitle = (to) => to.params.friendlyurl ? to.params.friendlyurl.replace(/-/g, ' ').replace(/\w\S*/g, word => !!word[0].match(/[a-z]/) ? word[0].toUpperCase() + word.substr(1) : word) : ''

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = `${nearestWithTitle.meta.title}${routeToTitle(to)} | Caleb Bergman`

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag))

  next()
})

export default router
