import Vue from "vue"
import Vuetify from "vuetify"
import App from "./App.vue"
import router from "./router"
import store from "./store/index"
import "vuetify/dist/vuetify.min.css"
import PrismicVue from 'prismic-vue'

Vue.config.productionTip = false

Vue.use(Vuetify)

const prismicAccessToken = 'MC5YTm8zb0JBQUFBQkJIc0Vr.77-9EF5Pa--_ve-_ve-_vQXvv71ba--_vQDvv73vv73vv73vv71ccXbvv73vv71-77-9BD3vv73vv70m77-9aQ'

Vue.use(PrismicVue, {
  endpoint: window.prismic.endpoint,
  linkResolver: (doc) => doc.type == 'page' ? `/page/${doc.uid}` : `/`,
  apiOptions: { accessToken: prismicAccessToken }
})

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
  mounted() {
    // Needed for pre-rendering: renderAfterDocumentEvent.
    setTimeout(() => {
      document.dispatchEvent(new Event('render-event'))
    }, 2000)
  }
})
