<template>
  <v-layout column>
    <CoreHeader large img="profile.jpg">
      <HomeHeader />
    </CoreHeader>
    <CoreBody>
      <HomeBody />
    </CoreBody>
  </v-layout>
</template>

<script>
export default {
  name: "home",
  components: {
    CoreBody: () => import('@/components/core/Body'),
    CoreHeader: () => import('@/components/core/Header'),
    HomeBody: () => import('@/components/home/Body'),
    HomeHeader: () => import('@/components/home/Header')
  }
}
</script>

<style scoped>

</style>
