<template>
  <v-app id="app" dark>
    <CoreToolbar />

    <CoreDrawer />

    <router-view />

    <CoreFooter />
  </v-app>
</template>

<script>
  export default {
    components: {
      CoreDrawer: () => import('@/components/core/Drawer'),
      CoreFooter: () => import('@/components/core/Footer'),
      CoreToolbar: () => import('@/components/core/Toolbar')
    }
  }
</script>

<style>
@import url('./site.css');

.application{
  background:#c9c9c9 !important;
}
</style>
